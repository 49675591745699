import React from "react"
import moment from "moment"

import CookieConsent from "react-cookie-consent"


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {

  }

  render() {

    return (
      <footer>
      <CookieConsent
        
        onAccept={e => {
          document.cookie = `cookie_accepted=true; expires=${moment().add(3, 'years').toDate()}`;
          window.dataLayer.push({

            "event": "cookieAccepted"

          });
          window.location.reload();
        }}
        buttonText={<span>Accetto</span>}
        cookieName="cookiePolicyConsent"
        expires={150}
        disableStyles={true}
        overlay={true}
        overlayClasses={'CookieOverlay'}
        buttonClasses={'cta small black'}
        buttonWrapperClasses={'btn'}
      >
        <p>
          Questo sito utilizza cookie, anche di terze parti, per proporti marketing e servizi in linea con le tue preferenze. Chiudendo questo banner, acconsenti all’uso dei cookie. Per maggiori informazioni o per negare il consenso a tutti o ad alcuni cookie, leggi l’<a href="https://www.gruppodigitouch.it/cookie-policy" target="_blank">Informativa Cookie.</a>
        </p>
      </CookieConsent>
      <div className="container">
        © {moment().format('YYYY')} DigiTouch SpA – P.I. 05677200965 – telefono +39 02 89295 100 – Dati LegaliDigiTouch SpA – Viale Vittorio Veneto, 22 – 20124 Milano – Numero Iscrizione Registro Imprese CCIAA Milano, Codice fiscale, P.IVA IT05677200965 – Numero REA MI-1839576 – Capitale sociale 694.834,75 euro I.V. – Numero azioni 13.896.695
      </div>
    </footer>
    );
  }
}


export default Footer;

