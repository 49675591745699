import React, { Fragment } from "react";
import { render } from "react-dom";

function hasScrolled() {
  window.onscroll = function (ev) {
    //console.log(window.scrollY)
    if ((window.scrollY) >= 160) {
      document.body.classList.add('hasScrolled')
    }
    else {
      document.body.classList.remove('hasScrolled')
    }
  };
}

class Header extends React.Component {
  componentDidMount() {
    hasScrolled()
  }
  render() {
    return (
      <header>
        <div className="container">
          <a target="_blank" className="dgt" href="https://gruppodigitouch.it"></a>
          <a target="_blank" className="conversion-e3" href="https://www.conversionagency.it/"></a>
        </div>
      </header>
    );
  }
}
export default Header;